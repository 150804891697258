import React, { lazy } from "react";
import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
} from "react-router-dom";
import { useAuthToken } from "./auth";
import SuspenseLoading from "./components/SuspenseLoading";

// import MainLayout from "./MainLayout";
// import Attendance from "./Attendance";
// import Login from "./Login";
// import { AdminEmployeePage, AdminParentPage } from "./pages/AdminPage";
// import { CreatePostPage } from "./pages/CreatePost";
// import { EmployeesAdminOverview } from "./pages/EmployeesAdminOverview";
// import { FeedPage } from "./pages/FeedPage";
// import { GroupsAdminOverview } from "./pages/GroupsAdminOverview";
// import { ParentPage } from "./pages/ParentAbsencesPage";
// import { PreschoolsAdminOverview } from "./pages/PreschoolsAdminOverview";
// import { StudentsAdminOverview } from "./pages/StudentsAdminOverview";
// import { useTranslation } from "react-i18next";

const Login = lazy(async () => await import("./Login").then((module) => ({ default: module.default })));
const MainLayout = lazy(async () => await import("./MainLayout").then((module) => ({ default: module.default })));
const FeedPage = lazy(async() => await import("./pages/FeedPage").then((module) => ({ default: module.FeedPage })));
const Attendance = lazy(async() => await import("./Attendance").then((module) => ({ default: module.default })));
const CreatePostPage = lazy(async() => await import("./pages/CreatePost").then((module) => ({ default: module.CreatePostPage })));
const AdminEmployeePage = lazy(async() => await import("./pages/AdminPage").then((module) => ({ default: module.AdminEmployeePage })));
const AdminParentPage = lazy(async() => await import("./pages/AdminPage").then((module) => ({ default: module.AdminParentPage })));
const EmployeesAdminOverview = lazy(async() => await import("./pages/EmployeesAdminOverview").then((module) => ({ default: module.EmployeesAdminOverview })));
const StudentsAdminOverview = lazy(async() => await import("./pages/StudentsAdminOverview").then((module) => ({ default: module.StudentsAdminOverview })));
const GroupsAdminOverview = lazy(async() => await import("./pages/GroupsAdminOverview").then((module) => ({ default: module.GroupsAdminOverview })));
const ParentPage = lazy(async() => await import("./pages/ParentAbsencesPage").then((module) => ({ default: module.ParentPage })));
const PreschoolsAdminOverview = lazy(async() => await import("./pages/PreschoolsAdminOverview").then((module) => ({ default: module.PreschoolsAdminOverview })));

const App: React.FC = () => {
	return (
		<Router>
			<Routes>
				<Route
					path="/"
					Component={function Index() {
						const nextPage = useAuthToken((x) => x.isLoggedIn)
							? "/feed"
							: "/login";
						return <Navigate to={nextPage} replace />;
					}}
				/>

				<Route path="/login" element={<SuspenseLoading><Login /></SuspenseLoading>} />
				{/* <Route path="/" Component={MainLayout}> */}
				<Route path="/" element={<SuspenseLoading><MainLayout /></SuspenseLoading>} >
					{/* Common */}
					<Route path="/feed" element={<SuspenseLoading><FeedPage /></SuspenseLoading>} />
					<Route path="/attendance" element={<SuspenseLoading><Attendance /></SuspenseLoading>} />
					{/* Employee */}
					<Route path="/create-post" element={<SuspenseLoading><CreatePostPage /></SuspenseLoading>} />
					<Route path="/employee/admin" element={<SuspenseLoading><AdminEmployeePage /></SuspenseLoading>} />
					<Route
						path="/employee/admin/employees"
						element={<SuspenseLoading><EmployeesAdminOverview /></SuspenseLoading>}
					/>
					<Route
						path="/employee/admin/students"
						element={<SuspenseLoading><StudentsAdminOverview /></SuspenseLoading>}
					/>
					<Route
						path="/employee/admin/groups"
						element={<SuspenseLoading><GroupsAdminOverview /></SuspenseLoading>} />
					<Route
						path="/employee/admin/preschools"
						element={<SuspenseLoading><PreschoolsAdminOverview /></SuspenseLoading>} />
					{/* Parent */}
					<Route path="/parent/admin" element={<SuspenseLoading><AdminParentPage /></SuspenseLoading>} />
          			<Route path="/parent/admin/absences" element={<SuspenseLoading><ParentPage /></SuspenseLoading>} />
          			<Route path="/parent/admin/children" element={<div>In progress...</div>} />
				</Route>
			</Routes>
			{/* Add other routes here */}
		</Router>
	);
};

export default App;
