import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { getApolloClient } from "./apollo";
import "./error-tracking.js";
import "./i18n/i18n";
import "./index.css";
import "./pwa.js";
import "./registerSW.js";

async function main() {
  const apolloClient = await getApolloClient();
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <ChakraProvider
        toastOptions={{
          defaultOptions: {
            position: "top",
          },
        }}
      >
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </ChakraProvider>
    </React.StrictMode>
  );
}

main().then(
  () => {
    console.log("main() done");
  },
  (error) => {
    console.error("main() error:", error);
  }
);
