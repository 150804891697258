import {
	ApolloClient,
	ApolloLink,
	type DefaultOptions,
	InMemoryCache,
	type NormalizedCacheObject,
} from "@apollo/client";
import { SentryLink } from "apollo-link-sentry";
import "./i18n/i18n";
import "./index.css";

import { setContext } from "@apollo/link-context";
import { createUploadLink } from "apollo-upload-client";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { useAuthToken } from "./auth";
import { GRAPHQL_URL } from "./config";

function getHttpLink() {
	return createUploadLink({
		uri: GRAPHQL_URL,
	});
}

function getAuthLink() {
	return setContext((_, { headers }) => {
		// Get the authentication token from the zustand store
		const token = useAuthToken.getState().token;

		// Return the headers to the context so the HTTP link can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : "",
				"Apollo-Require-Preflight": "true",
			},
		};
	});
}

function sentryLink() {
	return new SentryLink();
}

function getLink() {
	return ApolloLink.from([sentryLink(), getAuthLink(), getHttpLink()]);
}

async function getCache(): Promise<InMemoryCache> {
	const cache = new InMemoryCache();
	// await before instantiating ApolloClient, else queries might run before the cache is persisted
	await persistCache({
		cache,
		storage: new LocalStorageWrapper(window.localStorage),
	});
	return cache;
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export async function getApolloClient() {
	if (apolloClient) {
		return apolloClient;
	}
	const link = getLink();
	const cache = await getCache();
	const defaultOptions: DefaultOptions = {
		watchQuery: {
			fetchPolicy: "cache-and-network",
		},
	};
	apolloClient = new ApolloClient({
		link,
		cache,
		defaultOptions,
	});

	return apolloClient;
}

export function clearStore() {
	apolloClient?.clearStore();
}

export function logout() {
	clearStore();
	useAuthToken.getState().logout();
}
