const en = {
  enrolPasskey: {
    title: "Enable secure login",
    description:
      "Your device supports secure login with passkeys. Press enable to turn on extra security",
    enrol: "Enable",
  },
  admin: {
    menuPreschool: "Preschool",
    menuGroups: "Groups",
    menuStudents: "Children",
    menuStaff: "Staff",
    menuAbsences: "Absences",
    account: "{{firstName}} {{lastName}}",
  },
  adminEmployeesOverview: {
    searchEmployees: "Search by name or part of name",
    inviteEmployee: "Invite colleague",
    deleteColleague: "Delete colleague",
  },
  adminStudentsOverview: {
    searchStudents: "Search by name or SSN",
    createStudent: "Register new child",
    createStudentButton: "Register",
    editBasicInfo: "Edit basic info",
    switchGroup: "Change group",
    addRemoveParents: "Add/remove parents",
    editStudent: "Edit child",
    deleteStudent: "Delete child",
  },
  adminGroupsOverview: {
    searchGroups: "Search by group name",
    createGroup: "Create group",
    deleteGroup: "Delete group",
  },
  createGroupModal: {
    createGroup: "Create group",
    groupName: "Group name",
    groupEmoji: "Emoji symbol",
    minAge: "Minimum age",
    maxAge: "Maximum age",
  },
  inviteEmployeeModal: {
    sendInvitation: "Send invitation",
    scopeHelperText: "Choose the preschool or group the person works in",
  },
  swedishSSNInput: {
    placeholder: "YYYYMMDD-XXXX",
  },

  pwaInstallPrompt: {
    title: "Install our App",
    description:
      "You can install our Progressive Web App (PWA) on your iOS device to have a better experience.",
    instructions:
      "To install the PWA on iOS, open the Safari share menu and tap 'Add to Home Screen'.",
    continue: "Continue to Web App",
    install: "Install",
  },
  feed: {
    comment: "comment",
    comments: "comments",
    addComment: "Add comment",
  },
  createPost: {
    selectGoals: "Select goals",
    addMedia: "Add a photo or video",
    shareWithTagged: "Only with tagged",
    mediaAdded_one: "{{count}} photo added",
    mediaAdded_other: "{{count}} photos added",
    publishTo: "Publish to",
    onlyStudents: "Only these children?",
    shareWithMentioned: "Share only with tagged",
    description: "Description",
    selectMedia: "Choose photo or video",
    cameraError: "We need access to your camera function for this to work!",
    selectStudents: "Tag one or more children",
    publish: "Publish",
    postCreated: "🎉 Post published",
    postCreatedSuccessful: "Your post has been published!",
  },
  checkin: {
    notCheckedIn: "Not dropped off",
    checkedIn: "Present",
    checkedOut: "Picked up",
    notComingIn: "Absent today",
    checkIn: "Dropped off",
    checkOut: "Picked up",
  },
  profile: {
    newGroup: "New group",
    inviteColleague: "Invite colleague",
    inviteStaff: "Invite staff",
    addStudent: "Add child",
    createGroup: "Create a group",
    newStudent: "New child",
    vacations: "Vacations",
    start: "Start",
    end: "End",
  },
  studentScreen: {
    absence: {
      unknown: "Don't know",
      sick: "Sick",
      vacation: "Vacation",
    },
    absenceReason: "Reason",
  },
  auth: {
    code: "Code",
    signIn: "Sign in",
    signOut: "Sign out",
    verifyPhone: "Verify your phone number",
    loginWithYourPhoneNumber: "Login with your phone number",
    enterCode: "Slå in din SMS-kod",
    phoneNumberDoesNotExist: "Phone number does not exist",

  },
  studentModal: {
    cancelCheckIn: "Cancel check-in",
    cancelCheckOut: "Cancel check-out",
  },
  common: {
    save: "Save",
    finishLater: "Finish later",
    error: "Error",
    loading: "Loading",
    AppName: "Skildra",
    AppTagline: "Bringing preschools into the digital age",
    name: "Name",
    phoneNo: "Phone no.",
    groupSlashPreschool: "Group/Preschool",
    preschool: "Preschool",
    group: "Group",
    staff: "Staff",
    phone: "Phone",
    description: "Description",
    email: "Email",
    minimumAge: "Minimum age",
    maximumAge: "Max age",
    firstName: "First name",
    lastName: "Last name",
    ssn: "SSN",
    phone1: "Phone 1",
    phone2: "Phone 2",
    showProfile: "Show profile",
    create: "Create",
    update: "Update",
    cancel: "Cancel",
    invite: "Invite",
    date: "Date",
    createdAt: "Created",
    updatedAt: "Last updated",
    selection: {
      selectGoals: "Select goals",
      goalsSelected: "Selected goals",
      selectStudents: "Select children",
      studentsSelected: "Selected children",
    },
    back: "Back",
    noData: "No data",
    thisFieldRequired: "This field is required",
    areYouSure: "Are you sure?",
    screenloading: {
      error: "We failed to load this view, please try again later.",
      noGoal: "No goal",
      noGoals: "No goals",
      noPosts: "No posts created yet",
      noCheckIns: "No checkins",
      noStudent: "No student",
      noStudents: "No students",
    },
    status: {
      checkedIn: "Here",
      checkedOut: "Picked up",
      notSignedIn: "Not dropped off",
      notComingIn: "Not coming in today",
    },
    project: "Project",
    notes: "Notes",
    reportSick: {
      reported: "Reported sick",
      today: "Report sick today",
      tomorrow: "Report sick tomorrow",
    },
    logout: "Logout",
    login: "Login",
  },
  scopePicker: {
    selectScope: "Select preschool/group",
    selectPreschool: "Select preschool",
  },
  completeUserProfilePrompt: {
    completeYourProfile: "Complete your profile",
    yourProfileNeedsMoreInfo: "Your profile needs more information",
  },
  completeStudentPicturePrompt: {
    completeYourProfile: "Complete your profile",
    yourProfileNeedsMoreInfo: "Your profile needs more information",
    uploadPhotoOfYourChild: "Upload a photo of your child.",
  },
};

export default en;
