import { create } from "zustand";

// defined in the browser
type BeforeInstallPromptEvent = Event & {
  prompt: () => Promise<void>;
};

export const usePWAPrompt = create<{
  prompt: BeforeInstallPromptEvent | null;
  hasPrompt: boolean;
  setPrompt: (prompt: BeforeInstallPromptEvent) => void;
  trigger(): void;
}>((set, get) => ({
  prompt: null,
  hasPrompt: false,
  setPrompt: (prompt: BeforeInstallPromptEvent) =>
    set({ prompt, hasPrompt: true }),
  trigger() {
    get().prompt?.prompt();
  },
}));

window.addEventListener("beforeinstallprompt", (e: Event) => {
  const event = e as BeforeInstallPromptEvent;
  event.preventDefault();
  console.log("ping");
  usePWAPrompt.getState().setPrompt(event);
});