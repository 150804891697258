const sv = {
  enrolPasskey: {
    title: "Aktivera säker inloggning",
    description:
      "Din enhet stödjer säker inloggning med passkeys. Tryck på aktivera för att slå på extra säkerhet",
    enrol: "Aktivera",
  },
  admin: {
    menuPreschool: "Förskolan",
    menuGroups: "Avdelningar",
    menuStudents: "Barn",
    menuStaff: "Personal",
    menuAbsences: "Frånvaro",
    account: "{{firstName}} {{lastName}}",
  },
  adminEmployeesOverview: {
    searchEmployees: "Skriv en del av namnet på en person",
    inviteEmployee: "Bjud in kolllega",
    deleteColleague: "Ta bort kollega",
  },
  adminStudentsOverview: {
    searchStudents: "Sök på namn eller personnummer",
    createStudent: "Registrera nytt barn",
    createStudentButton: "Registrera",
    editBasicInfo: "Redigera information",
    switchGroup: "Byt avdelning",
    addRemoveParents: "Lägg till/ta bort förälder",
    editStudent: "Redigera barn",
    deleteStudent: "Ta bort barn",
  },
  createGroupModal: {
    createGroup: "Skapa avdelning",
    groupName: "Avdelningsnamn",
    groupEmoji: "Emojisymbol",
    minAge: "Minsta ålder",
    maxAge: "Max ålder",
  },
  pwaInstallPrompt: {
    title: "Installera vår App",
    description:
      "Du kan installera vår App på din iOS-enhet för en bättre upplevelse.",
    instructions:
      "För att installera PWA på iOS, öppna Safari delningsmenyn och tryck på 'Lägg till på hemskärmen'.",
    continue: "Fortsätt till webbappen",
    install: "Installera",
  },
  adminGroupsOverview: {
    searchGroups: "Sök på avdelningsnamn",
    createGroup: "Skapa avdelning",
    deleteGroup: "Ta bort avdelning",
  },
  inviteEmployeeModal: {
    sendInvitation: "Skicka inbjudan",
    scopeHelperText: "Välj vilken förskola eller avdelning personen jobbar på",
  },
  swedishSSNInput: {
    placeholder: "ÅÅÅÅMMDD-XXXX",
  },
  feed: {
    comment: "kommentar",
    comments: "kommentarer",
    addComment: "Kommentera inlägg",
  },
  createPost: {
    selectGoals: "Välj mål",
    addMedia: "Lägg till en bild eller video",
    shareWithTagged: "Enbart med taggade",
    mediaAdded_one: "{{count}} foto tilllagt",
    mediaAdded_other: "{{count}} foton tillagda",
    publishTo: "Publicera till",
    onlyStudents: "Endast dessa barn?",
    shareWithMentioned: "Dela endast med taggade",
    description: "Beskrivning",
    selectMedia: "Välj bild eller video",
    cameraError:
      "Vi behöver åtkomst till din kamerafunktion för att detta ska fungera!",
    selectStudents: "Tagga en eller flera barn",
    publish: "Publicera",
    postCreated: "🎉 Post publicerad",
    postCreatedSuccessful: "Din post har nu publicerats!",
  },
  checkin: {
    notCheckedIn: "Inte lämnad",
    checkedIn: "Här",
    checkedOut: "Hämtad",
    notComingIn: "Borta idag",
    checkIn: "Blivit lämnad",
    checkOut: "Blivit hämtad",
  },
  profile: {
    newGroup: "Ny avdelning",
    inviteColleague: "Bjud in kollega",
    inviteStaff: "Bjud in personal",
    addStudent: "Lägg till barn",
    createGroup: "Skapa en avdelning",
    newStudent: "Nytt barn",
    vacations: "Lediga",
    start: "Början",
    end: "Slut",
  },
  studentScreen: {
    absence: {
      unknown: "Vet ej",
      sick: "Sjuk",
      vacation: "Ledig",
    },
    absenceReason: "Anledning",
  },
  auth: {
    code: "Kod",
    signIn: "Logga in",
    signOut: "Logga ut",
    verifyPhone: "Verifiera ditt telefonnummer",
    loginWithYourPhoneNumber: "Logga in med ditt Mobilnummer",
    enterCode: "Slå in din SMS-kod",
    phoneNumberDoesNotExist: "Telefonnumret finns inte",
  },
  studentModal: {
    cancelCheckIn: "Ångra incheckning",
    cancelCheckOut: "Ångra utcheckning",
  },
  common: {
    save: "Spara",
    finishLater: "Gör klart senare",
    error: "Fel",
    loading: "Loading",
    AppName: "Skildra",
    AppTagline: "Förskolor in i den digitala tidsåldern",
    name: "Namn",
    phoneNo: "Telefonnr.",
    groupSlashPreschool: "Avdelning/Förskola",
    preschool: "Förskola",
    group: "Avdelning",
    staff: "Personal",
    phone: "Telefon",
    description: "Beskrivning",
    email: "E-mail",
    minimumAge: "Minimum ålder",
    maximumAge: "Max ålder",
    firstName: "Förnamn",
    lastName: "Efternamn",
    ssn: "Personnummer",
    phone1: "Telefon 1",
    phone2: "Telefon 2",
    showProfile: "Visa profil",
    create: "Skapa",
    update: "Uppdatera",
    cancel: "Avbryt",
    invite: "Bjud in",
    date: "Datum",
    createdAt: "Skapad",
    updatedAt: "Senast uppdaterad",
    birthday: "Födelsedag",
    selection: {
      selectGoals: "Välj mål",
      goalsSelected: "Valda mål",
      selectStudents: "Välj barn",
      studentsSelected: "Valda barn",
    },
    back: "Tillbaka",
    noData: "Inga data",
    thisFieldRequired: "Detta fält är obligatoriskt",
    areYouSure: "Är du säker?",
    screenloading: {
      error: "Vi misslyckades med att ladda denna vy, försök igen senare.",
      noGoal: "No goal",
      noGoals: "No goals",
      noPosts: "No posts created yet",
      noCheckIns: "No checkins",
      noStudent: "No student",
      noStudents: "No students",
    },
    status: {
      checkedIn: "På förskolan",
      checkedOut: "Har blivit hämtad",
      notSignedIn: "Inte lämnad",
      notComingIn: "Kommer inte in idag",
    },
    project: "Projekt",
    notes: "Anteckningar",
    reportSick: {
      reported: "Sjukanmäld",
      today: "Sjukanmäl idag",
      tomorrow: "Sjukanmäl imorgon",
    },
    logout: "Logga ut",
    login: "Logga in",
  },
  scopePicker: {
    selectScope: "Välj förskola/avdelning",
    selectPreschool: "Välj förskola",
  },
  completeUserProfilePrompt: {
    completeYourProfile: "Gör klart din profil",
    yourProfileNeedsMoreInfo: "Vi behöver lite mer information om dig.",
  },
  completeStudentPicturePrompt: {
    completeYourProfile: "Gör klart din profil",
    yourProfileNeedsMoreInfo: "Vi behöver lite mer information om dig.",
    uploadPhotoOfYourChild: "Ladda upp en bild på ditt barn.",
  },
};

export default sv;
