const apiUrlFromEnv = import.meta.env.VITE_API_URL;

function inferDevAPIURL() {
  const host = window.location.hostname;
  const defaultPort = 4000;
  return `http://${host}:${defaultPort}/`;
}

const BASE_API_URL = apiUrlFromEnv ?? inferDevAPIURL();
export const API_URL = apiUrlFromEnv ?? inferDevAPIURL();

const UPLOADS_URL = new URL("uploads/", BASE_API_URL).toString();

export const GRAPHQL_URL = new URL("graphql/", BASE_API_URL).toString();

export function constructUploadsURL(
  path: string | null | undefined
): string | undefined {
  if (!path) {
    return undefined;
  }
  return new URL(path, UPLOADS_URL).toString();
}
