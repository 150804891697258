// src/i18n/i18n.ts

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import sv from "./sv";
i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem("language") ?? "sv",
  nonExplicitSupportedLngs: true,
  // debug: true,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: "common",
  resources: {
    sv,
    en,
  },
});

export default i18n;
