import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IdentityState {
  token: string;
  setToken: (newToken: string) => void;
  isLoggedIn: boolean;
  logout: () => void;
}

export const useAuthToken = create<IdentityState>()(
  persist(
    (set, get) => ({
      token: "",
      isLoggedIn: false,
      setToken: (newToken: string) => { set({ token: newToken, isLoggedIn: true }); },
      logout: () => { set({ token: "", isLoggedIn: false }); },
    }),
    {
      name: "auth-token", // unique name
    }
  )
);

export function setAuthToken(token: string) {
  useAuthToken.getState().setToken(token);
}
