import React, { Suspense, type PropsWithChildren } from "react";
import { VStack, Spinner } from "@chakra-ui/react";

export default function SuspenseLoading({ children }: PropsWithChildren<{}>) {
	return (
		<Suspense
			fallback={
				<VStack height={"100vh"} justifyContent={"center"}>
					<Spinner
						thickness="4px"
						speed="0.65s"
						emptyColor="gray.200"
						color="blue.500"
						size="xl"
					/>
				</VStack>
			}
		>
			{children}
		</Suspense>
	);
}
